import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["productsBtn", "blogBtn"]

  initialize() {
    this.productsContainer = document.getElementById("products-container")
    this.blogContainer = document.getElementById("blog-container")
    this.searchFilter = document.getElementById("search-filter")
  }

  enableBlog(e) {
    e.preventDefault()

    this.productsBtnTarget.classList.remove("btn-dark")
    this.productsBtnTarget.classList.add("btn-pastel-dark")
    this.blogBtnTarget.classList.remove("btn-pastel-dark")
    this.blogBtnTarget.classList.add("btn-dark")

    this.productsContainer.classList.remove("d-block")
    this.productsContainer.classList.add("d-none")
    if (this.searchFilter) {
      this.searchFilter.classList.remove("d-block")
      this.searchFilter.classList.add("d-none")
    }
    this.blogContainer.classList.remove("d-none")
    this.blogContainer.classList.add("d-block")
  }

  enableProducts(e) {
    e.preventDefault()

    this.productsBtnTarget.classList.remove("btn-pastel-dark")
    this.productsBtnTarget.classList.add("btn-dark")
    this.blogBtnTarget.classList.remove("btn-dark")
    this.blogBtnTarget.classList.add("btn-pastel-dark")

    this.productsContainer.classList.remove("d-none")
    this.productsContainer.classList.add("d-block")
    if (this.searchFilter) {
      this.searchFilter.classList.remove("d-none")
      this.searchFilter.classList.add("d-block")
    }
    this.blogContainer.classList.remove("d-block")
    this.blogContainer.classList.add("d-none")
  }
}
