import { BridgeComponent } from "@hotwired/hotwire-native-bridge"
import { BridgeElement } from "@hotwired/hotwire-native-bridge"
import { Collapse } from "bootstrap"

export default class extends BridgeComponent {
  static component = "search"
  static targets = [ "button", "collapse", "input" ]

  connect() {
    super.connect()
    this.notifyBridgeOfConnect()

    console.log("connect")
    console.log("---")

    this.collapseTarget.addEventListener('shown.bs.collapse', event => {
      setTimeout(() => {
        this.ensureInputFocus()
      }, 100)
    })
  }

  ensureInputFocus() {
    console.log("ensureInputFocus")
    console.log("---")

    requestAnimationFrame(() => {
      console.log("input focus")
      console.log("---")

      console.log("Before focus:", document.activeElement)

      // this.inputTarget.dispatchEvent(new Event('click', { bubbles: true }))
      this.inputTarget.focus()

      // this.send("focusInput", { label: this.inputTarget.id }, () => {
      //   console.log("focusInput callback")
      //   console.log("---")

      //   this.inputTarget.focus()
      // })

      console.log("After focus:", document.activeElement)
    })
  }

  notifyBridgeOfConnect() {
    const label = this.bridgeElement.title

    this.send("connect", { label }, () => {
      this.buttonTarget.click()
      // this.ensureInputFocus()
    })
  }
}
