import { Controller } from "@hotwired/stimulus"
import { Toast } from "bootstrap"

export default class extends Controller {
  showProductBookmarkCreated(e) {
    let toast = new Toast(document.getElementById('toastProductBookmarkCreated'))
    toast.show()
  }

  showProductBookmarkDeleted(e) {
    let toast = new Toast(document.getElementById('toastProductBookmarkDeleted'))
    toast.show()
  }

  showSellerBookmarkCreated(e) {
    let toast = new Toast(document.getElementById('toastSellerBookmarkCreated'))
    toast.show()
  }

  showSellerBookmarkDeleted(e) {
    let toast = new Toast(document.getElementById('toastSellerBookmarkDeleted'))
    toast.show()
  }
}
